import React, { useState, useCallback, useEffect } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { Icon } from "@iconify/react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "rsuite";
import TableContainer from "../../components/Common/TableContainer";
import { userData } from "../../store/getProfileData";
// import CourseListTable from "../CourseTable/courseListTable";

const SlideExams = () => {
  const navigate = useNavigate();
  document.title = "Slide Exams | Matary ";
  const [modal, setmodal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(false);
  const [book, setBook] = useState(false);
  const [book_url, setBookUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState(false);
  const [courses, setCourses] = useState([]);
  const [assignData, setAssignData] = useState({
    exam_id: "",
    course_id: "",
    start_date: "",
    end_date: "",
  });
  const [book_title, set_book_title] = useState("");
  const [edit, setEdit] = useState(null);

  // === New State Variables for Assigned Courses Modal ===
  const [assignedCoursesModal, setAssignedCoursesModal] = useState(false);
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  // ======================================================

  const columns = [
    {
      Header: "#",
      Cell: (cell) => {
        return <>{cell.cell.row.original?.exam_id}</>;
      },
    },
    {
      Header: "Exam Name",
      accessor: "exam_name",
    },
    {
      Header: "Time",
      accessor: "timer",
    },
    {
      Header: "Exam Time For",
      Cell: (cell) => {
        return <>{cell.cell.row.original?.for}</>;
      },
    },
    {
      Header: "Action",
      Cell: (cell) => {
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-light btn-sm"
                tag="button"
                data-bs-toggle="dropdown"
                direction="start"
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem
                  onClick={() => {
                    setEdit(true);
                    setExamData({
                      ...cell.cell.row.original,
                      timer_for: cell.cell.row.original?.for !== "qus",
                    });
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setAssignData({
                      ...assignData,
                      exam_id: cell.cell.row.original?.exam_id,
                    });
                    toggleAssignModal();
                  }}
                >
                  Assign to Course
                </DropdownItem>

                {/* === New Dropdown Item to View Assigned Courses === */}
                <DropdownItem
                  onClick={() => {
                    viewAssignedCourses(cell.cell.row.original);
                  }}
                >
                  View Assigned Courses
                </DropdownItem>
                {/* ======================================================= */}

                <DropdownItem
                  onClick={() => {
                    navigate("Slides/" + cell.cell.row.original?.exam_id);
                  }}
                >
                  Slides
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (edit) {
      setBookUrl(edit?.book_url);
      setNumberOfPages(edit?.pages_count);
      set_book_title(edit?.book_title);
    }
  }, [edit]);

  useEffect(() => {
    getEbooks();
    getCourses();
  }, []);

  const getEbooks = async () => {
    try {
      const get = await axios.get(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/select_slide_exams.php"
      );
      if (Array.isArray(get.message)) setEbooks(get.message);
      else setEbooks([]);
    } catch (err) {
      setEbooks([]);
    }
  };

  const getCourses = async () => {
    try {
      const get = await axios.get(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/courses/select_courses.php"
      );
      if (Array.isArray(get)) setCourses(get);
      else setCourses([]);
    } catch (err) {
      setCourses([]);
    }
  };

  const assignExam = async () => {
    try {
      const payload = { ...assignData };
      // If unassigning, include assign_id
      if (assignData.assign_id) {
        payload.assign_id = assignData.assign_id;
      }

      const response = await axios.post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/assign_slide_exam_to_course.php",
        payload
      );

      if (response.status === "success") {
        toast.success("Operation successful");
        toggleAssignModal();
        getEbooks(); // Refresh the exams list to reflect changes
      } else {
        toast.error("Operation failed");
      }
    } catch (err) {
      toast.error("Error during operation");
    }
  };

  // === Updated Function to Unassign a Course ===
  const unassignExam = (assign_id, exam_id) => {
    // To unassign, we'll use the same assignExam function by setting assign_id
    setAssignData({
      course_id: assign_id,
      exam_id
    });
    assignExam();
  };
  // ===========================================

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      // No file selected
      return;
    }
    setBook(file);
    var reader = new FileReader();
    reader.readAsBinaryString(event.target.files[0]);
    reader.onloadend = function () {
      var count = reader.result.match(/\/Type[\s]*\/Page[^s]/g)?.length;
      if (count) {
        setNumberOfPages(count);
        set_book_title(file?.name);
      } else {
        setNumberOfPages(false);
        set_book_title("");
      }
    };
  };
  const uploadPdf = async () => {
    setLoading(true);
    const formData = new FormData();
    if (book) {
      formData.append("file_attachment", book);
      try {
        const url = await axios.post(
          "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/uploud_pdf.php",
          formData
        );
        console.log(url);
        if (url.status === 200 && url.message === "success") {
          setBookUrl(url.message);
          toast.success("File Uploaded Successfully");
        } else {
          toast.error(url.message);
        }
      } catch (err) {
        toast.error("Error uploading file");
      }
    }
    setLoading(false);
  };
  const addBook = async (e) => {
    e.preventDefault();
    console.log()
    try {
      examData.timer_for = examData.timer_for ? "qus" : "exam"
      const add = await axios.post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/insert_exam.php",
        examData
      );
      if (add.status === "success") {
        toast.success("Added");
        setmodal(false); // Close the modal after adding
        setBook(false);
        getEbooks();
        setExamData({
          exam_name: "",
          timer_for: false,
          timer: 0,
        });
      } else {
        toast.error(add.message);
      }
    } catch (err) {
      toast.error("Error adding exam");
    }
  };

  const editBook = async (e) => {
    e.preventDefault();
    try {
      const add = await axios.post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/edit_exam_data.php",
        examData
      );
      if (add.status === 200 && add.message === "success") {
        toast.success("Updated");
        setEdit(null);
        getEbooks();
        setExamData({
          exam_name: "",
          timer_for: false,
          timer: 0,
        });
      } else {
        toast.error(add.message);
      }
    } catch (err) {
      toast.error("Error updating exam");
    }
  };

  const toggle = useCallback(() => {
    setmodal(!modal);
  }, [modal]);

  const toggleAssignModal = useCallback(() => {
    setAssignModal(!assignModal);
  }, [assignModal]);

  const [examData, setExamData] = useState({
    exam_name: "",
    timer_for: false, // (false for => 'qus', true for => 'exam')
    timer: 0,
  });

  const changeFunction = (e) => {
    const { name, value, type, checked } = e?.target;
    setExamData({
      ...examData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // === Updated Function to View Assigned Courses ===
  const viewAssignedCourses = (exam) => {
    setSelectedExam(exam);
    setAssignedCourses(exam.courses || []);
    setAssignedCoursesModal(exam?.exam_id);
  };
  // ===========================================

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div id="table-invoices-list">
            {!ebooks ? (
              <Loader />
            ) : ebooks?.length ? (
              <TableContainer
                columns={columns}
                data={ebooks}
                isGlobalFilter={true}
                customPageSize={10}
                className="Invoice table"
                buttonsChildren={
                  <div className="header-btns">
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        setmodal(true);
                      }}
                    >
                      Add New Slide Exam
                    </button>
                  </div>
                }
              />
            ) : (
              <h4>No Slide Exams</h4>
            )}
          </div>
        </Container>

        <ToastContainer />
      </div>

      {/* === Existing Add/Edit Exam Modal === */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {edit ? "Edit Slide Exam" : "Add New Slide Exam"}
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              if (edit) {
                editBook(e);
              } else {
                addBook(e);
              }
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Title</Label>
                  <Input
                    name="exam_name"
                    type="text"
                    onChange={changeFunction}
                    value={examData?.exam_name}
                    required
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label d-flex align-items-center gap-2">
                    <span>Time For {examData?.timer_for ? "Exam" : "Question"}</span>
                    <input
                      checked={examData?.timer_for}
                      type="checkbox"
                      name="timer_for"
                      id="timer_for"
                      onChange={(e) =>
                        changeFunction({
                          target: {
                            name: "timer_for",
                            value: e.target.checked,
                            checked: e.target.checked,
                            type: "checkbox",
                          },
                        })
                      }
                    />
                  </Label>
                </div>

                <div className="mb-3 d-flex align-items-center">
                  <Label className="form-label d-flex align-items-center gap-2">
                    <span>Time</span>
                    <input
                      value={examData?.timer}
                      max={200}
                      type="range"
                      name="timer"
                      id="timer"
                      onChange={changeFunction}
                    />
                    <b>:{examData?.timer}</b>
                  </Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    {edit ? "Update" : "Save"}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* ======================================== */}

      {/* === Assign Exam to Course Modal === */}
      <Modal isOpen={assignModal} toggle={toggleAssignModal}>
        <ModalHeader toggle={toggleAssignModal} tag="h4">
          Assign Exam to Course
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              assignExam();
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Course</Label>
                  <Input
                    type="select"
                    onChange={(e) =>
                      setAssignData({ ...assignData, course_id: e.target.value })
                    }
                    value={assignData.course_id}
                    required
                  >
                    <option value="">Select Course</option>
                    {courses.map((course) => (
                      <option key={course.course_id} value={course.course_id}>
                        {course.course_name}
                      </option>
                    ))}
                  </Input>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">Start Date</Label>
                  <Input
                    type="datetime-local"
                    onChange={(e) =>
                      setAssignData({ ...assignData, start_date: e.target.value })
                    }
                    value={assignData.start_date}
                    required
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3">
                  <Label className="form-label">End Date</Label>
                  <Input
                    type="datetime-local"
                    onChange={(e) =>
                      setAssignData({ ...assignData, end_date: e.target.value })
                    }
                    value={assignData.end_date}
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Assign
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* ==================================== */}

      {/* === Assigned Courses Modal === */}
      <Modal isOpen={assignedCoursesModal} toggle={() => setAssignedCoursesModal(false)} size="lg">
        <ModalHeader toggle={() => setAssignedCoursesModal(false)} tag="h4">
          Assigned Courses for "{selectedExam?.exam_name}"
        </ModalHeader>
        <ModalBody>
          {assignedCourses.length > 0 ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Course Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assignedCourses.map((course, index) => (
                  <tr key={course.assign_id}>
                    <td>{index + 1}</td>
                    <td>{course.course_name}</td>
                    <td>{course.start_date}</td>
                    <td>{course.end_date}</td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => unassignExam(course.course_id, assignedCoursesModal)}
                      >
                        Unassign
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No courses assigned to this exam.</p>
          )}
        </ModalBody>
      </Modal>
      {/* ================================ */}
      <Modal isOpen={edit} toggle={() => setEdit(false)}>
        <ModalHeader toggle={() => setEdit(false)} tag="h4">
          Edit Slide Exam
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editBook(e);
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Title</Label>
                  <Input
                    name="exam_name"
                    type="text"
                    onChange={changeFunction}
                    value={examData?.exam_name}
                  />
                </div>

              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label d-flex align-items-center  gap-2">
                    <span>Time For {examData?.timer_for ? "Exam" : "Question"}</span>
                    <input checked={examData?.timer_for} type="checkbox" name="timer_for" id="timer_for"
                      onChange={(e) => changeFunction(
                        {
                          target: {
                            name: "timer_for",
                            value: e.target.checked
                          }
                        }
                      )} />
                  </Label>
                </div>

                <div className="mb-3 d-flex align-items-center">
                  <Label className="form-label d-flex align-items-center gap-2">
                    <span>Time</span>
                    <input value={examData?.timer} max={200} type="range" name="timer" id="timer"
                      onChange={changeFunction} />
                    <b>:{examData?.timer}</b>
                  </Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      {/* === Existing Edit Exam Modal === */}
      {/* (Already integrated into the Add/Edit Exam Modal above) */}
      {/* ================================== */}
    </React.Fragment>
  );
};

export default SlideExams;
