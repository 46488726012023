import { userData } from "../../store/getProfileData";

const SidebarData = [
  {
    label: "Menu",
    isMainMenu: true,
  },
  {
    label: "Dashboard",
    icon: "bx bx-tachometer icon nav-icon",
    url: "/dashboard",
    issubMenubadge: true,
    bgcolor: "bg-success",
    badgeValue: "5+",
  },

  {
    label: "Courses",
    isMainMenu: true,
  },
  {
    label: "Coming Soon Courses",
    icon: "bx bx-time-five icon nav-icon", // updated icon
    url: "/ComingSoonCourses",
  },
  {
    label: "Courses",
    icon: "bx bx-book icon nav-icon", // updated icon
    url: "/courses-list",
  },
  {
    label: "Images",
    url: "/ImagesFolder",
    icon: "bx bx-image icon nav-icon", // updated icon
  },
  {
    label: "BoadCast",
    url: "/BoadCast",
    icon: "bx bx-radio icon nav-icon", // updated icon
  },
  {
    label: "Posts",
    url: "/posts",
    icon: "bx bx-message-square icon nav-icon", // updated icon
  },
  {
    label: "Bank Search Question",
    url: "/SearchQuestion",
    icon: "bx bx-search icon nav-icon", // updated icon
  },
  {
    label: "Search True False Question",
    url: "/SearchTrueFalseQuestion",
    icon: "bx bx-question-mark icon nav-icon", // updated icon
  },
  {
    label: "Inquires",
    url: "/inquires",
    icon: "bx bx-help-circle icon nav-icon", // corrected icon
  },
  {
    label: "E-book Store",
    url: "/e-book-store",
    icon: "bx bx-book-reader icon nav-icon", // updated icon
  },
  {
    label: "Voices",
    url: "/VoiceStore",
    icon: "bx bx-microphone icon nav-icon", // updated icon
  },
  {
    label: "Users",
    url: userData?.user_id == 27 || userData?.user_id == 37 ? "/Users" : "/",
    icon: "bx bx-user icon nav-icon", // updated icon
  },
  {
    label: "Q-Bank",
    url: "/CourseGradesQBank",
    icon: "bx bx-coin icon nav-icon", // updated icon
  },
  {
    label: "Add Courses Cards",
    icon: "bx bx-card icon nav-icon", // updated icon
    url: "/copouns-list/add-copoun",
  },
  {
    label: "Add Grades Cards",
    icon: "bx bx-card icon nav-icon", // updated icon
    url: "/AddGradesCopoun",
  },
  {
    label: "Videos Groups",
    icon: "bx bx-video icon nav-icon", // updated icon
    url: "/Groups",
  },
  {
    label: "Charge",
    icon: "bx bx-credit-card icon nav-icon", // updated icon
    url: "/Charge",
  },
  {
    label: "General Agenda",
    url: "/AgenaUniversities",
    icon: "bx bx-calendar icon nav-icon", // updated icon
  },
  {
    label: "Universities",
    icon: "bx bx-buildings icon nav-icon", // updated icon
    url: "/universities",
  },
  {
    label: "Subscription",
    icon: "bx bx-receipt icon nav-icon", // updated icon
    subItem: [
      { sublabel: "Subscription", link: "/subscription" },
      { sublabel: "Canceled Subscription", link: "/canceledsubscription" },
    ],
  },

  {
    label: "Students",
    icon: "bx bx-user icon nav-icon", // corrected icon
    subItem: [
      { sublabel: "Students Reports", link: "/studentsreports" },
      { sublabel: "List Students", link: "/students" },
      { sublabel: "Don't Marry", link: "/DoNotMarry" },
    ],
  },
  {
    label: "Exam",
    icon: "bx bx-edit icon nav-icon", // updated icon
    url: "/publicexam",
  },
  {
    label: "Slide Exams",
    icon: "bx bx-edit icon nav-icon", // updated icon
    url: "/SlideExams",
  },{
    label: "Reports",
    icon: "bx bx-file icon nav-icon", // updated icon
    url: "/ReportsLessons",
  },
  {
    label: "Live ( Zoom )",
    icon: "bx bx-video-recording icon nav-icon", // updated icon
    url: "/lives",
  },
  {
    label: "ClassPoint",
    icon: "bx bx-pointer icon nav-icon", // updated icon
    url: "/ClassPoint",
  },
  {
    label: "TimeLine",
    icon: "bx bx-time icon nav-icon", // updated icon
    url: "/TimeLine",
  },
  {
    label: "Zoom Emails",
    icon: "bx bx-envelope icon nav-icon", // updated icon
    url: "/ZoomEmail",
  },
  {
    label: "Top & Not To Marry - Students",
    url: "/NewUniversities",
    icon: "bx bx-notepad icon nav-icon", // updated icon
  },
  {
    label: "Polling",
    url: "/uniPolling",
    icon: "bx bx-poll icon nav-icon", // updated icon
  },
  {
    label: "Information",
    icon: "bx bx-info-circle icon nav-icon", // updated icon
    subItem: [
      { sublabel: "Policy", link: "/policy" },
      { sublabel: "CallCenter", link: "/callcenter" },
    ],
  },
];

export default SidebarData;
