import React, { useState } from "react";
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { Icon } from "@iconify/react";
import axios from "axios";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "rsuite";
import TableContainer from "../../components/Common/TableContainer";
import { userData } from "../../store/getProfileData";
import { uploadImage } from "../Units/UnitTable/uploadImage";
// import CourseListTable from "../CourseTable/courseListTable";

const Slides = () => {
  const navigate = useNavigate();
  document.title = "Slides | Matary ";
  const [modal, setmodal] = useState(false);
  const [numberOfPages, setNumberOfPages] = useState(false);
  const [book, setBook] = useState(false);
  const [book_url, setBookUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ebooks, setEbooks] = useState(false);
  const [book_title, set_book_title] = useState("");
  const [edit, setEdit] = useState(null);
  const columns = [

    {
      Header: "title",
      accessor: "slide_text",
    },

    {
      Header: "Image",
      Cell: (cell) => {
        return (
          <><img src={cell.cell.row.original?.slide_image} alt="" /></>
        );
      },
    },

    {
      Header: "Action",
      Cell: (cell) => {
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn btn-light btn-sm"
                tag="button"
                data-bs-toggle="dropdown"
                direction="start"
              >
                <i className="bx bx-dots-horizontal-rounded"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">

                <DropdownItem
                  onClick={() => {
                    setEdit(cell.cell.row.original);
                    setSlideQuestionData({
                      "exam_id": slide_exam_id,
                      "slide_text": cell.cell.row.original?.slide_text,
                      "slide_image": cell.cell.row.original?.slide_image
                    })
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    navigate("Questions/" + cell.cell.row.original?.slide_id)
                  }}
                >
                  Questions
                </DropdownItem>

              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];
  useEffect(() => {
    if (edit) {
      setBookUrl(edit?.book_url);
      setNumberOfPages(edit?.pages_count);
      set_book_title(edit?.book_title);
    }
  }, [edit]);
  useEffect(() => {
    getEbooks();
  }, []);
  const { slide_exam_id } = useParams()
  const [slideQuestionData, setSlideQuestionData] = useState({
    "exam_id": slide_exam_id,
    "slide_text": "",
    "slide_image": ""
  })
  const slideQuestionDataChange = (e) => {
    const { name, value } = e?.target;
    setSlideQuestionData({
      ...slideQuestionData,
      [name]: value
    })
  }
  const getEbooks = async () => {
    try {
      const get = await axios.post(
        "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/select_slide_exam_qs.php", {
        exam_id: slide_exam_id
      }
      );
      if (Array.isArray(get?.message)) setEbooks(get.message);
      else setEbooks([]);
    } catch (err) {
      setEbooks([]);
    }
  };


  const addBook = async (e) => {

    if (slideQuestionData?.imageFile) {
      slideQuestionData.slide_image = await uploadImage(slideQuestionData?.imageFile)
      delete slideQuestionData?.imageFile
    }


    // https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/ebooks/insert_ebooks.php
    const add = await axios.post(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/add_slide_question.php",
      slideQuestionData
    );
    if (add.status == "success") {
      toast.success("Added");
      setmodal(false);
      getEbooks();
      setSlideQuestionData({
        "exam_id": slide_exam_id,
        "slide_text": "",
        "slide_image": ""
      })
    } else {
      toast.error(add.message);
    }
  };

  const editBook = async (e) => {

    if (slideQuestionData?.imageFile) {
      slideQuestionData.slide_image = await uploadImage(slideQuestionData?.imageFile)
      delete slideQuestionData?.imageFile
    }


    // https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/ebooks/insert_ebooks.php
    const add = await axios.post(
      "https://back.dr-elmatary.com/El_Matary_Platform/platform/admin/slide_exam/edit_slide_exam_question.php",
      slideQuestionData
    );
    if (add.status == "success") {
      toast.success("Updated");
      setEdit(false);
      getEbooks();
      setSlideQuestionData({
        "exam_id": slide_exam_id,
        "slide_text": "",
        "slide_image": ""
      })
    } else {
      toast.error(add.message);
    }
  };
  const toggle = useCallback(() => {
    if (modal) {
      setmodal(false);
    } else {
      setmodal(true);
    }
  }, [modal]);
  const permissions = userData?.permissions;
  useEffect(() => {
    if (!edit && !modal) {
      setSlideQuestionData({
        "exam_id": slide_exam_id,
        "slide_text": "",
        "slide_image": ""
      })
    }
  }, [modal, edit])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <div className="header-btns">
            <button
              className="btn btn-success"
              onClick={() => {
                setmodal(true);
              }}
            >
              Add New Slide Exam
            </button>
          </div>
          <div id="table-invoices-list">

            {!ebooks ? (
              <Loader />
            ) : ebooks?.length ? (
              <TableContainer
                columns={columns}
                data={ebooks}
                isGlobalFilter={true}
                customPageSize={10}
                className="Invoice table"

              />
            ) : (
              <h4>No Slide Exams</h4>
            )}
          </div>
        </Container>

        <ToastContainer />
      </div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          Add New Slide Question
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              addBook(e);
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Title</Label>
                  <Input
                    name="slide_text"
                    type="text"
                    onChange={slideQuestionDataChange}
                    value={slideQuestionData?.slide_text}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Image</Label>
                  <Input
                    name="slide_text"
                    type="file"
                    onChange={(e) => setSlideQuestionData({
                      ...slideQuestionData,
                      imageFile: e.target.files[0]
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={edit} toggle={() => setEdit(false)}>
        <ModalHeader toggle={() => setEdit(false)} tag="h4">
          Edit Slide Question
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              editBook(e);
              return false;
            }}
          >
            <Row>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Text</Label>
                  <Input
                    name="slide_text"
                    type="text"
                    onChange={slideQuestionDataChange}
                    value={slideQuestionData?.slide_text}
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Label className="form-label">Slide Exam Image</Label>
                  <Input
                    name="slide_text"
                    type="file"
                    onChange={(e) => setSlideQuestionData({
                      ...slideQuestionData,
                      imageFile: e.target.files[0]
                    })}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Save
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Slides;
